@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html, body {
  height: 100%;
  margin: 0;
  overflow: auto;
  font-family: 'Roboto', sans-serif;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  
  display: flex;
  
  min-height: 100%; 
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #228B22, #FFDB58, #87CEFA, #E6E6FA, #DDA0DD);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 128, 0, 0.2);
  transition: all 0.3s ease;
}

.nav-item:hover {
  box-shadow: 0px 4px 20px rgba(0, 128, 0, 0.4);
  transform: translateY(-5px);
}

.navbar {
  position: left;
  top: 40%;
  left: 5%;
  display: left;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.container {
  display: flex;
  max-width: 80%;
  min-height: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  margin: auto;
  padding: 0;
  overflow: hidden;
}

.left, .right {
  padding: 0px;
  margin: 10px;
  
}

.left {
  background-color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 2px 20px rgba(0, 128, 0, 0.2);
  width: 25%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 128, 0, 0.05);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: -1;
  transition: all 0.3s ease;
}

.left:hover {
  box-shadow: 0px 4px 30px rgba(0, 128, 0, 0.4);
  transform: translateY(-5px);
}

.left:hover::before {
  background-color: rgba(0, 128, 0, 0.1);
}

.profile-picture {
  width: 475px;
  height: 400px;
  object-fit: cover;
  margin: -20px -20px 0;
  border-top-left-radius: 10px;
  border: 5px solid rgba(0, 128, 0, 0.5);
  box-shadow: 0px 0px 40px rgba(0, 128, 0, 0.2);
  transition: all 0.3s ease;
}

.profile-picture:hover {
  box-shadow: 0px 0px 50px rgba(0, 128, 0, 0.4);
  transform: scale(1.02);
}

.jobs p {
  margin: 0;
  height: 1px;
  color: green;
}

.links a {
  position: absolute;
  text-decoration: none;
  margin: 5px;
  color: blueviolet;
}

.right {
  background-color: whitesmoke;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 2px 20px rgba(0, 128, 0, 0.2);
  flex: 3;
  padding: 20px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  overflow: auto;
  height: 600px;
  flex-grow: 1;
}

.right::before {
  content: '';
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 128, 0, 0.05);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: -1;
  transition: all 0.3s ease;
}

.right:hover {
  box-shadow: 0px 4px 30px rgba(0, 128, 0, 0.4);
  transform: translateY(-5px);
}

.right:hover::before {
  background-color: rgba(0, 128, 0, 0.1);
}

@media (max-width: 992px) {
  .left {
    width: 100%;
    min-width: auto;
  }

  .right {
    width: 100%;
  }

  .navbar {
    flex-direction: row;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-around;
    gap: 0;
  }

  .container {
    flex-direction: column;
    min-height: auto;
  }
}

@media (max-width: 768px) {
  .left {
    padding: 10px;
  }

  .right {
    padding: 10px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .left {
    padding: 5px;
  }

  .right {
    padding: 5px;
  }

  .profile-picture {
    width: 100%;
    height: auto;
    margin: 0;
    border-top-left-radius: 0;
  }
}



